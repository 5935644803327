import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

body {
  overflow: overlay;
  overflow-x: hidden;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
  /* background: #010606; */
}

::-webkit-scrollbar-thumb {
  background: #2c2d32;
  border-radius: 0px;
  transition: all 1s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(0deg, #00a195, #2cd483);
}
`
