import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { gsap, Power3 } from "gsap"

import { FaBars } from "react-icons/fa"
import { Link } from "gatsby"
import { menuData } from "../data/menu-data"
import { Button } from "./button"

const Header = () => {
  //gsap anim
  let header = useRef(null)

  useEffect(() => {
    //nav var
    const nav = header

    gsap.from(nav, {
      opacity: 0,
      duration: 2,
      y: -80,
      ease: Power3.easeOut,
      delay: 1,
    })
  })

  return (
    <Nav>
      <NavContainer ref={el => (header = el)}>
        <NavBrand to="/">ZIGMA</NavBrand>
        <BarsButton />
        <NavMenu>
          {menuData.map((item, index) => (
            <NavLink to={item.link} key={index}>
              {item.title}
            </NavLink>
          ))}
          <NavBtn>
            <Button primary="true" to="/signup">
              Sign Up
            </Button>
          </NavBtn>
        </NavMenu>
      </NavContainer>
    </Nav>
  )
}

export default Header

const Nav = styled.nav`
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 10;
  transition: 0.5s all ease;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1320px;
`

const NavBrand = styled(Link)`
  color: #2c2d32;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
`

const NavLink = styled(Link)`
  color: #2c2d32;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
`
const BarsButton = styled(FaBars)`
  display: none;
  color: #2c2d32;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #2c2d32;
  /* margin-right: -48px; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  padding-left: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
