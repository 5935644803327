import styled from "styled-components"
import { Link } from "gatsby"

export const Button = styled(Link)`
  background: ${({ primary }) => (primary ? "#fff" : "#2cd483")};
  white-space: none;
  padding: ${({ big }) => (big ? "16px 60px" : "10px 32px")};
  color: #2c2d32;
  font-size: ${({ big }) => (big ? "20px" : "16px")};
  outline: none;
  border: none;
  border-radius: 100px;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;

  &:hover {
    color: #2cd483;
    transform: translateY(-1px);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  }
`
